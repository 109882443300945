.App {
  text-align: center;
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000;
}

.App-link {
  color: #61dafb;
}

#logo {
  margin: auto;
  padding-top: 10px;
}

.home {
  background-color: #fff;
  padding-top: 5%;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  /* color: #000; */
}

a {
  color: #fff;
}