.BlogMain {
  margin-left: 10%;
  margin-right: 10%;
}

.BlogMain h2 {
  text-align: center;
}

.BlogPiece {
  margin-left: 20%;
  margin-right: 20%;
}
